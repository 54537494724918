

	import { Component, Prop, Vue } from 'vue-property-decorator';
	import { debounce } from 'lodash';
	import { namespace } from 'vuex-class';

	import { ContractorBranch } from '@/modules/settings/store/modules/contractor-branches/types/ContractorBranchesState';
	import { Actions, Getters } from '@/modules/settings/store/modules/contractor-branches/types/StoreTypes';

	const contractorBranches = namespace('settings/contractor-branches');

	@Component
	export default class SelectContractorTypeTable extends Vue {
		@Prop({ type: Boolean, default: false }) value!: boolean;

		@contractorBranches.Action(Actions.FETCH_ALL_CONTRACTOR_BRANCHES) fetchAllContractorBranches!: () => void;
		@contractorBranches.Getter(Getters.GET_ALL_BRANCHES) contractorTypes!: ContractorBranch[];

		selectedContractorTypeIds = []

		created() {
			this.fetchAllContractorBranches();
		}
	}
